<template>
    <b-card class="form--component">
      <b-form @submit="onSubmit">
        <b-container>
          <b-row class="pb-3">
            <b-col cols="2" class="align-items-center d-flex"><p class="form--text">Detalle del motivo <span class="text-danger form--required">*</span></p></b-col>
            <b-col cols="10" > <b-form-select required v-model="form.detail_motive_code" :options="options"></b-form-select> </b-col>
          </b-row>
          <b-row class="pb-3">
            <b-col cols="2" class="align-items-center d-flex"><p class="form--text">Mensaje <span class="text-danger form--required">*</span></p></b-col>
            <b-col cols="10" >
              <b-form-textarea
              required
              v-model="form.message"
              placeholder="Describí brevemente el problema"
              rows="3"
              max-rows="6"
              no-resize>
              </b-form-textarea>
            </b-col>
          </b-row>
          <!-- <b-row class="pb-3">
            <b-col cols="12" class="p-0">
              <b-container>
                <b-row>
                  <b-col cols="2" class="align-items-center d-flex"> <p class="form--text">Precio <span class="text-danger form--required">*</span></p> </b-col>
                  <b-col cols="2">
                    <b-form-input required v-model="form.price" type="number"></b-form-input>
                  </b-col>
                  <b-col cols="3" class="align-items-center d-flex justify-content-end"> <p class="form--text" :style="{color: loading.emptyInvoiceFile ? 'red': ''}">Factura de compra <span class="text-danger form--required">*</span></p> </b-col>
                  <b-col cols="5">
                    <b-form-file name="invoice" v-model="form.invoice" style="display: none;" id="invoice" class="mt-3" plain></b-form-file>
                    <label for="invoice" class="btn btn-outline-secondary mb-0 form--button-file">
                        foto | archivo <feather-icon size="25" icon="UploadCloudIcon"/>
                    </label>
                     <div v-if="form.invoice">Archivo seleccionado: {{ form.invoice ? form.invoice.name : '' }}</div>
                  </b-col>
                </b-row>
              </b-container>
            </b-col>
          </b-row> -->
          <b-row class="pb-3">
            <b-col cols="6" class="p-0">
              <b-container>
                <b-row>
                  <b-col cols="4" class="align-items-center d-flex"> <p class="form--text">Evidencia del problema<span class="text-danger form--required">*</span></p> </b-col>
                  <b-col cols="8">          
                    <b-form-file name="evidence" v-model="form.evidence" style="display: none;" id="evidence" class="mt-3" multiple plain>
                    </b-form-file>
                    <label for="evidence" class="btn mb-0 form--button-file" :class="state ? 'btn-outline-secondary' : 'btn-outline-danger'">
                        foto | archivo <feather-icon size="25" icon="UploadCloudIcon"/>
                    </label>
                  </b-col>
                </b-row>
              </b-container>
            </b-col>
            <b-col cols="6" class="p-0">
              <div v-if="form.evidence">
                <p>Archivos seleccionados:
                <b-badge variant="dark">{{ form.evidence[0].name}} + {{form.evidence.length - 1 }} Archivos adicionales</b-badge></p> 
              </div>
            </b-col>
          </b-row>
          
          <b-row class="justify-content-center">
            <b-button 
              type="submit" 
              variant="personalice" 
              class="w-25 d-flex align-items-center justify-content-center"
              :disabled="loading.buttonDisabled" 
              :style="{background: customizationForm.buttonColor, borderColor: customizationForm.buttonColor, color: customizationForm.buttonTextColor}" >
              <b-spinner class="mr-05" label="Spinning" v-if="loading.buttonDisabled"></b-spinner>Enviar
            </b-button>
          </b-row>
        </b-container>
      </b-form>
    </b-card>
</template>
<script>
import {
  BContainer,
  BRow,
  BCol,
  BFormSelect,
  BButton
} from 'bootstrap-vue'
import BaseServices from '@/store/services/index'
export default {
  components: {
    BContainer,
    BRow,
    BCol,
    BFormSelect,
    BButton
  },
  props: [
    'customizationForm',
    'token'
  ],
  data() {
    return {
      form: {},
      options: [
        {
          value: 'DAMAGED',
          text: 'Me llego dañado el envio'
        },
        {
          value: 'MISSING_PACKAGE',
          text: 'Falto un caja'
        },
        {
          value: 'MISSING',
          text: 'Falto un/os producto/s'
        },
        {
          value: 'REJECTED',
          text: 'Producto equivocado'
        },
        {
          value: 'REJECTED',
          text: 'No he recibido mi envío'
        }
      ],
      loading: {
        buttonDisabled: false,
        emptyInvoiceFile: false
      },
      baseService: new BaseServices(this)
    }
  },
  computed: {
    state() {
      return this.form?.evidence?.length >= 1
    }
  },
  methods: {
    onSubmit(event) {
      event.preventDefault()
      if (this.state) {
        this.loading.buttonDisabled = true
        this.form.token = this.token
        document.getElementById('loading-bg').style.display = 'block'
        this.baseService.callNewReport('sendTicketMessage', this.form)
          .then(response => {
            this.$emit('sendForm', response.customization)
          })
          .catch(err => {
            this.$emit('sendForm', null)
          })
          .finally(() => {
            this.loading.buttonDisabled = false
          })
      } else {
        this.$alert('Debes adjuntar al menos un archivo')
      }
    }
  }
}
</script>
<style lang="scss" scoped>
  .form--component {
    // max-width: 1384px;
    width: 100%;
    color: #5E5873;

  .card-body {
    padding: 50px;
  }

  .form--button {
    width: 100%;
    height: 66px;
    font-size: 18px;
  }
  .form--button-file {
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 10px;
    font-size: 18px;

    &:hover {
      background-color: #82868b ;
      color: white;
    }
  }
  .form--required {
    font-size: 30px;
  }
  .form--text {
    margin-bottom: 0;
    // width: 95%;
  }
  .custom-select,
  .form-control {
    font-size: 19px;
  }
 }
</style>