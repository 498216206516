import { render, staticRenderFns } from "./ReportProblem.vue?vue&type=template&id=5c19565f&scoped=true&"
import script from "./ReportProblem.vue?vue&type=script&lang=js&"
export * from "./ReportProblem.vue?vue&type=script&lang=js&"
import style0 from "./ReportProblem.vue?vue&type=style&index=0&id=5c19565f&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "5c19565f",
  null
  
)

export default component.exports