<template>
  <b-card class="form--component-direction">
    <div>
      <form-render :form.sync="form" :fields="fieldsForm" @send="onSubmit" containerButtonsClass="col-sm-12">
        <template #buttonsGroupSlot>
          <b-button type="submit" 
            variant="personalice" 
            class="d-flex align-items-center justify-content-center"
            :disabled="loading.buttonDisabled" 
            :style="{background: customizationForm.buttonColor, borderColor: customizationForm.buttonColor, color: customizationForm.buttonTextColor}">
            <b-spinner class="mr-05" label="Spinning" v-if="loading.buttonDisabled"></b-spinner>
            <feather-icon  icon="TruckIcon" class="mr-50" size="20"/>Verificar dirección 
          </b-button>
        </template>
        <template #prefixNumberSlot>
          <p class="mb-0 font-small-4"> +{{relatedData && relatedData.country && relatedData.country.phone_code ? relatedData.country.phone_code : 56}}</p>
        </template>
        <template #numberLabelSlot>
          <label class="label-form m-0"><span>Teléfono</span> <span class="text-danger" v-if="setValidation('phone') === 'required'">*</span></label>
        </template>
      </form-render>
    </div>
    <div>
      <form-render :form.sync="form" :fields="fieldsForm2" @send="onSubmit">
        <template #mapSlot>
          <div>
            <google-map-vue
              v-if="relatedData.customer_data.address.formatted_address"
              :noMarker="mapOptions.noMarker"
              :infoMap="mapOptions.infoMap"
              :update="updateValue"
              :size="mapOptions.size"
              :full_address.sync="mapAddress">
            </google-map-vue>
          </div>
        </template>
      </form-render>
    </div>
  </b-card>
</template>
<script>
import BaseServices from '@/store/services/index'
import GoogleMapVue from '@/views/components/custom/map/GoogleMap.vue'
export default {
  components: {
    GoogleMapVue
  },
  props: [
    'customizationForm',
    'token',
    'relatedData'
  ],
  data() {
    return {
      form: {
        full_address: ''
      },
      fieldsForm: [],
      fieldsForm2: [],
      options: [],
      loading: {
        buttonDisabled: false,
        emptyInvoiceFile: false
      },
      labelPlace: 'Comuna',
      baseService: new BaseServices(this),
      mapOptions: {
        showMap: false,
        noMarker: false,
        infoMap: {
          draggable: true
        },
        size: {
          'width': '42vw',
          'height': '42vh',
          'max-width': '90%',
          'margin-left': 'auto',
          'margin-right': 'auto'
        }
      }
    }
  },
  mounted() {
    this.setInitialData()
  },
  computed: {
    mapAddress() {
      const placeText = this.form?.place?.text ?? ''
      const addressText = this.form?.full_address ?? ''
      return `${placeText}, ${addressText}`
    },
    placeOptions() {
      return this.relatedData && this.relatedData.places && this.relatedData.places.length > 0 ? this.relatedData.places : []
    }
  },
  methods: {
    setInitialData() {
      if (this.relatedData && this.relatedData.places && this.relatedData.places.length > 0) {
        this.fieldsForm = [{fieldType: 'FieldSelect', name: 'place', label: this.labelPlace, containerClass: 'col-12 container-info text-dropdown-black', mainColor: 'black',
          options: this.placeOptions, searchOnType: { nChars: 999}, validation: this.setValidation('place')}]
      }
      this.fieldsForm = [...this.fieldsForm,
        {fieldType: 'FieldInput', name: 'full_address', label: 'Dirección', containerClass: 'col-12 container-info', validation: this.setValidation('full_address'), changeDebounce: 1000},
        {name: 'numberLabelSlot', useSlot:true, containerClass: 'col-12 container-info m-0'},
        {name: 'prefixNumberSlot', useSlot:true, containerClass: 'align-items-lg-start mt-06 col-1 flex justify-content-center p-0'},
        {fieldType: 'FieldInput', name: 'phone', type: 'number', containerClass: 'col-11 container-info pl-0', validation: this.setValidation('phone')},
        {fieldType: 'FieldTextarea', name: 'ref1', label: 'Referencia adicional para facilitar ubicación', containerClass: 'col-12 container-info', validation: this.setValidation('ref1')},
        {fieldType: 'FieldTextarea', name: 'ref2', label: 'Instrucción adicional para facilitar la entrega', containerClass: 'col-12 container-info', validation: this.setValidation('ref2')},
        {name: 'mapSlot', useSlot:true, containerClass: 'align-items-lg-center col-12 flex justify-content-center p-0'},
        {name: 'buttonsGroupSlot', useSlot:true, containerClass: 'col-12'}
      ]
      this.fieldsForm2 = [...this.fieldsForm2,
        {name: 'mapSlot', useSlot:true, containerClass: 'align-items-lg-center col-12 flex justify-content-center p-0'}
      ]
      this.setFormDefault()
    },
    setValidation(source) {
      const value = this.relatedData?.extra_fields ? this.relatedData?.extra_fields?.find(el => el.name === source) : []
      return value.validation
    },
    onSubmit(event) {
      // event.preventDefault()
      this.loading.buttonDisabled = true
      const extraFields = {
        place: JSON.stringify(this.form.place.value),
        place_id: this.form.place.id,
        full_address: this.form.full_address,
        phone: this.form.phone,
        ref1: this.form.ref1,
        ref2: this.form.ref2,
        location: this.form.location
      }
      const dataSend = {
        token: this.token,
        detail_motive_code: 'RIGHT_ADDRESS',
        extra_fields: JSON.stringify(extraFields) 
      }
      document.getElementById('loading-bg').style.display = 'block'
      this.baseService.callNewReport('sendTicketMessage', dataSend)
        .then(response => {
          this.$emit('sendForm', response.customization)
        })
        .catch(err => {
          this.$emit('sendForm', null)
        })
        .finally(() => {
          this.loading.buttonDisabled = false
        })
    },
    setFormDefault() {
      const full_address = this.relatedData.customer_data.address.full_address
      const place = this.placeOptions.find(el => el.id === this.relatedData.customer_data.address.place_id)
      const phone = this.relatedData?.customer_data?.phone ?? null
      this.form = {
        ...this.form,
        full_address,
        place,
        phone
      }
    },
    updateValue(data) {
      this.form.location = {...data.location}
    }
  }
}
</script>

<style lang="scss">
  .form--component-direction {
    // max-width: 1384px;
    width: 100%;
    color: #5E5873;
    
    .card-body {
      display: grid;
      grid-template-columns: 1fr 1fr;
    }

    .text-dropdown-black {
        font-size: 1rem;
      }
  }
</style>